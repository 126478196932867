import './scss/homepage.scss';

import Swiper from 'swiper';

$(document).ready(function () {
  var swiper = new Swiper('#news-swiper', {
    pagination: '.swiper-pagination',
    slidesPerView: 2,
    centeredSlides: true,
    paginationClickable: true,
    spaceBetween: 30,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev'
  });
});
